import React from 'react';
// import { Link } from 'react-router-dom';
import Text from 'antd/lib/typography/Text';
import { Row, Col } from 'antd/lib/grid';
import Empty from 'antd/lib/empty';

import { IllustEmptyIcon } from 'icons';

interface Props {
    notFound: boolean;
}

export default function EmptyListComponent(props: Props): JSX.Element {
    const { notFound } = props;
    return (
        <div className='cvat-empty-projects-list'>
            <Empty
                image={<IllustEmptyIcon />}
                description={notFound ? (
                    <Text strong>No results matched your search...</Text>
                ) : (
                    <>
                        <Row justify='center' align='middle'>
                            <Col>
                                <Text strong>No projects created yet ...</Text>
                            </Col>
                        </Row>
                    </>
                )}
            />
        </div>
    );
}
