import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'antd/lib/modal';
import Menu from 'antd/lib/menu';
import Icon from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';

import {
    UserIcon, TrashIcon,
} from 'icons';
import { Input } from 'antd';
import { removeOrganizationAsync } from 'actions/organization-actions';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import OrganizationInvitationModal from './organization-invitation-modal';

interface Props {
    organizationInstance: any;
}

export default function OrganizationActionsMenuComponent(props: Props): JSX.Element {
    const { organizationInstance } = props;

    const dispatch = useDispatch();
    const [visibleInviteModal, setVisibleInviteModal] = useState<boolean>(false);

    const menuItem: ItemType[] = [
        {
            key: 'invite-user',
            title: 'Invite user',
            label: 'Invite user',
            itemIcon: <Icon component={UserIcon} className='cvat-organization-actions-menu-icon' />,
            onClick: () => setVisibleInviteModal(true),
        },
        {
            key: 'remove-org',
            title: 'Remove Organization',
            label: 'Remove',
            itemIcon: <Icon component={TrashIcon} className='cvat-organization-actions-menu-icon' />,
            onClick: () => {
                const modal = Modal.confirm({
                    onOk: () => {
                        dispatch(removeOrganizationAsync(organizationInstance));
                    },
                    content: (
                        <div className='cvat-remove-organization-submit'>
                            <Text type='warning'>
                                To remove the organization, enter its short name below
                            </Text>
                            <Input
                                placeholder='Organization name'
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    modal.update({
                                        okButtonProps: {
                                            disabled:
                                                event.target.value !== organizationInstance.slug,
                                            danger: true,
                                        },
                                    });
                                }}
                            />
                        </div>
                    ),
                    title: 'Remove Organization',
                    okButtonProps: {
                        disabled: true,
                        danger: true,
                    },
                    okText: 'Remove',
                    cancelText: 'Cancel',
                    className: 'cvat-remove-organization',
                });
            },
        },
    ]

    return (
        <>
            <Menu
                selectable={false}
                className='cvat-organization-actions-menu'
                items={menuItem}
            />
            <OrganizationInvitationModal
                organizationInstance={organizationInstance}
                visible={visibleInviteModal}
                setVisible={(visible: boolean) => setVisibleInviteModal(visible)}

            />
        </>
    );
}
