import React from 'react';

import SVGSALMONLogo from './assets/salmon-logo.svg';
import SVGCursorIcon from './assets/cursor-icon.svg';
import SVGMoveIcon from './assets/move-icon.svg';
import SVGRotateIcon from './assets/rotate-icon.svg';
import SVGFitIcon from './assets/fit-to-window-icon.svg';
import SVGZoomIcon from './assets/zoom-icon.svg';
import SVGRectangleIcon from './assets/rectangle-icon.svg';
import SVGPolygonIcon from './assets/polygon-icon.svg';
import SVGPointIcon from './assets/point-icon.svg';
import SVGEllipseIcon from './assets/ellipse-icon.svg';
import SVGPolylineIcon from './assets/polyline-icon.svg';
import SVGTagIcon from './assets/tag-icon.svg';
import SVGMergeIcon from './assets/merge-icon.svg';
import SVGGroupIcon from './assets/group-icon.svg';
import SVGSplitIcon from './assets/split-icon.svg';
import SVGMainMenuIcon from './assets/main-menu-icon.svg';
import SVGSaveIcon from './assets/save-icon.svg';
import SVGUndoIcon from './assets/undo-icon.svg';
import SVGRedoIcon from './assets/redo-icon.svg';
import SVGFirstIcon from './assets/first-icon.svg';
import SVGBackJumpIcon from './assets/back-jump-icon.svg';
import SVGPreviousIcon from './assets/previous-icon.svg';
import SVGPreviousFilteredIcon from './assets/previous-filtered-icon.svg';
import SVGPreviousEmptyIcon from './assets/previous-empty-icon.svg';
import SVGPlayIcon from './assets/play-icon.svg';
import SVGPauseIcon from './assets/pause-icon.svg';
import SVGNextIcon from './assets/next-icon.svg';
import SVGNextFilteredIcon from './assets/next-filtered-icon.svg';
import SVGNextEmptyIcon from './assets/next-empty-icon.svg';
import SVGForwardJumpIcon from './assets/forward-jump-icon.svg';
import SVGLastIcon from './assets/last-icon.svg';
import SVGInfoIcon from './assets/info-icon.svg';
import SVGFullscreenIcon from './assets/fullscreen-icon.svg';
import SVGObjectOutsideIcon from './assets/object-outside-icon.svg';
import SVGBackgroundIcon from './assets/background-icon.svg';
import SVGForegroundIcon from './assets/foreground-icon.svg';
import SVGCubeIcon from './assets/cube-icon.svg';
import SVGSkeletonIcon from './assets/skeleton-icon.svg';
import SVGResetPerspectiveIcon from './assets/reset-perspective.svg';
import SVGColorizeIcon from './assets/colorize-icon.svg';
import SVGSelectColorIcon from './assets/select-color-icon.svg';
import SVGAITools from './assets/ai-tools-icon.svg';
import SVGBrain from './assets/brain.svg';
import SVGOpenCV from './assets/opencv.svg';
import SVGFilterIcon from './assets/object-filter-icon.svg';
import SVGFilterSolidIcon from './assets/filter-solid-icon.svg';
import SVGCVATAzureProvider from './assets/vscode-icons_file-type-azure.svg';
import SVGCVATS3Provider from './assets/S3.svg';
import SVGCVATGoogleCloudProvider from './assets/google-cloud.svg';
import SVGRestoreIcon from './assets/restore-icon.svg';
import SVGBrushIcon from './assets/brush-icon.svg';
import SVGEraserIcon from './assets/eraser-icon.svg';
import SVGPolygonPlusIcon from './assets/polygon-plus.svg';
import SVGPolygonMinusIcon from './assets/polygon-minus.svg';
import SVGBackArrowIcon from './assets/back-arrow-icon.svg';
import SVGClearIcon from './assets/clear-icon.svg';
import SVGShowPasswordIcon from './assets/show-password.svg';
import SVGCheckIcon from './assets/check-icon.svg';
import SVGSignInIcon from './assets/sign-in-icon.svg';
import SVGSignUpIcon from './assets/sign-up-icon.svg';
import SVGPasswordIcon from './assets/password-icon.svg';
import SVGIconRotateIcon from './assets/icon-rotate-icon.svg';
import SVGColorChipIcon from './assets/color-chip-icon.svg';
import SVGEyedropperIcon from './assets/eyedropper-icon.svg';
import SVGTrashIcon from './assets/trash-icon.svg';
import SVGIllustEmptyIcon from './assets/illust-empty-icon.svg';
import SVGDataDownloadIcon from './assets/data-download-icon.svg';
import SVGEditIcon from './assets/edit-icon.svg';
import SVGServerIcon from './assets/server-icon.svg';
import SVGTrashGrayIcon from './assets/trash-gray-icon.svg';
import SVGCloseGrayIcon from './assets/close-gray-icon.svg';
import SVGIllustFileIcon from './assets/illust-file-icon.svg';
import SVGIllustWarningIcon from './assets/illust-warning.svg';
import SVGFileNeutralGrayIcon from './assets/file-neutral-gray-icon.svg';
import SVGUserIcon from './assets/user-icon.svg';
import SVGDownIcon from './assets/down-icon.svg';
import SVGUpIcon from './assets/up-icon.svg';
import SVGPlusIcon from './assets/plus-icon.svg';
import SVGTrashWeakGrayIcon from './assets/trash-weak-gray-icon.svg';
import SVGEditWeakGrayIcon from './assets/edit-weak-gray-icon.svg';
import SVGWarningIcon from './assets/warning-icon.svg';
import SVGConfirmIcon from './assets/confirm-icon.svg';
import SVGEyeOffGrayIcon from './assets/eye-off-gray-icon.svg';
import SVGListIcon from './assets/list-icon.svg';
import SVGSettingIcon from './assets/setting-icon.svg';
import SVGAdminIcon from './assets/admin-icon.svg';
import SVGLogoutIcon from './assets/logout-icon.svg';
import SVGOrganizationIcon from './assets/organization-icon.svg';
import SVGCheckedIcon from './assets/checked-icon.svg';
import SVGLockIcon from './assets/lock-icon.svg';
import SVGStrokeIcon from './assets/stroke-icon.svg';
import SVGEyeIcon from './assets/eye-icon.svg';
import SVGPinIcon from './assets/pin-icon.svg';
import SVGUnlockIcon from './assets/unlock-icon.svg';
import SVGNonStrokeIcon from './assets/non-stroke-icon.svg';
import SVGEyeOffIcon from './assets/eye-off-icon.svg';
import SVGUnpinIcon from './assets/unpin-icon.svg';
import SVGCopyIcon from './assets/copy-icon.svg';
import SVGTrackIcon from './assets/track-icon.svg';
import SVGTrackActiveIcon from './assets/track-active-icon.svg';
import SVGStarIcon from './assets/star-icon.svg';
import SVGStarFillIcon from './assets/star-fill-icon.svg';
import SVGLocationIcon from './assets/location-icon.svg';
import SVGEnterIcon from './assets/enter-icon.svg';
import SVGJobActionIcon from './assets/job-action-icon.svg';
import SVGExitIcon from './assets/exit-icon.svg';
import SVGChangeIcon from './assets/change-icon.svg';

export const SALMONLogo = React.memo((): JSX.Element => <SVGSALMONLogo />);
export const CursorIcon = React.memo((): JSX.Element => <SVGCursorIcon />);
export const MoveIcon = React.memo((): JSX.Element => <SVGMoveIcon />);
export const RotateIcon = React.memo((): JSX.Element => <SVGRotateIcon />);
export const FitIcon = React.memo((): JSX.Element => <SVGFitIcon />);
export const ZoomIcon = React.memo((): JSX.Element => <SVGZoomIcon />);
export const RectangleIcon = React.memo((): JSX.Element => <SVGRectangleIcon />);
export const PolygonIcon = React.memo((): JSX.Element => <SVGPolygonIcon />);
export const PointIcon = React.memo((): JSX.Element => <SVGPointIcon />);
export const EllipseIcon = React.memo((): JSX.Element => <SVGEllipseIcon />);
export const PolylineIcon = React.memo((): JSX.Element => <SVGPolylineIcon />);
export const TagIcon = React.memo((): JSX.Element => <SVGTagIcon />);
export const MergeIcon = React.memo((): JSX.Element => <SVGMergeIcon />);
export const GroupIcon = React.memo((): JSX.Element => <SVGGroupIcon />);
export const SplitIcon = React.memo((): JSX.Element => <SVGSplitIcon />);
export const MainMenuIcon = React.memo((): JSX.Element => <SVGMainMenuIcon />);
export const SaveIcon = React.memo((): JSX.Element => <SVGSaveIcon />);
export const UndoIcon = React.memo((): JSX.Element => <SVGUndoIcon />);
export const RedoIcon = React.memo((): JSX.Element => <SVGRedoIcon />);
export const FirstIcon = React.memo((): JSX.Element => <SVGFirstIcon />);
export const BackJumpIcon = React.memo((): JSX.Element => <SVGBackJumpIcon />);
export const PreviousIcon = React.memo((): JSX.Element => <SVGPreviousIcon />);
export const PreviousFilteredIcon = React.memo((): JSX.Element => <SVGPreviousFilteredIcon />);
export const PreviousEmptyIcon = React.memo((): JSX.Element => <SVGPreviousEmptyIcon />);
export const PauseIcon = React.memo((): JSX.Element => <SVGPauseIcon />);
export const PlayIcon = React.memo((): JSX.Element => <SVGPlayIcon />);
export const NextIcon = React.memo((): JSX.Element => <SVGNextIcon />);
export const NextFilteredIcon = React.memo((): JSX.Element => <SVGNextFilteredIcon />);
export const NextEmptyIcon = React.memo((): JSX.Element => <SVGNextEmptyIcon />);
export const ForwardJumpIcon = React.memo((): JSX.Element => <SVGForwardJumpIcon />);
export const LastIcon = React.memo((): JSX.Element => <SVGLastIcon />);
export const InfoIcon = React.memo((): JSX.Element => <SVGInfoIcon />);
export const FullscreenIcon = React.memo((): JSX.Element => <SVGFullscreenIcon />);
export const ObjectOutsideIcon = React.memo((): JSX.Element => <SVGObjectOutsideIcon />);
export const BackgroundIcon = React.memo((): JSX.Element => <SVGBackgroundIcon />);
export const ForegroundIcon = React.memo((): JSX.Element => <SVGForegroundIcon />);
export const CubeIcon = React.memo((): JSX.Element => <SVGCubeIcon />);
export const SkeletonIcon = React.memo((): JSX.Element => <SVGSkeletonIcon />);
export const ResetPerspectiveIcon = React.memo((): JSX.Element => <SVGResetPerspectiveIcon />);
export const AIToolsIcon = React.memo((): JSX.Element => <SVGAITools />);
export const ColorizeIcon = React.memo((): JSX.Element => <SVGColorizeIcon />);
export const SelectColorIcon = React.memo((): JSX.Element => <SVGSelectColorIcon />);
export const BrainIcon = React.memo((): JSX.Element => <SVGBrain />);
export const OpenCVIcon = React.memo((): JSX.Element => <SVGOpenCV />);
export const FilterIcon = React.memo((): JSX.Element => <SVGFilterIcon />);
export const FilterSolidIcon = React.memo((): JSX.Element => <SVGFilterSolidIcon />);
export const AzureProvider = React.memo((): JSX.Element => <SVGCVATAzureProvider />);
export const S3Provider = React.memo((): JSX.Element => <SVGCVATS3Provider />);
export const GoogleCloudProvider = React.memo((): JSX.Element => <SVGCVATGoogleCloudProvider />);
export const RestoreIcon = React.memo((): JSX.Element => <SVGRestoreIcon />);
export const BrushIcon = React.memo((): JSX.Element => <SVGBrushIcon />);
export const EraserIcon = React.memo((): JSX.Element => <SVGEraserIcon />);
export const PolygonPlusIcon = React.memo((): JSX.Element => <SVGPolygonPlusIcon />);
export const PolygonMinusIcon = React.memo((): JSX.Element => <SVGPolygonMinusIcon />);
export const BackArrowIcon = React.memo((): JSX.Element => <SVGBackArrowIcon />);
export const ClearIcon = React.memo((): JSX.Element => <SVGClearIcon />);
export const ShowPasswordIcon = React.memo((): JSX.Element => <SVGShowPasswordIcon />);
export const CheckIcon = React.memo((): JSX.Element => <SVGCheckIcon />);
export const SignInIcon = React.memo((): JSX.Element => <SVGSignInIcon />);
export const SignUpIcon = React.memo((): JSX.Element => <SVGSignUpIcon />);
export const PasswordIcon = React.memo((): JSX.Element => <SVGPasswordIcon />);
export const IconRotateIcon = React.memo((): JSX.Element => <SVGIconRotateIcon />);
export const ColorChipIcon = React.memo((): JSX.Element => <SVGColorChipIcon />);
export const EyedropperIcon = React.memo((): JSX.Element => <SVGEyedropperIcon />);
export const TrashIcon = React.memo((): JSX.Element => <SVGTrashIcon />);
export const IllustEmptyIcon = React.memo((): JSX.Element => <SVGIllustEmptyIcon />);
export const DataDownloadIcon = React.memo((): JSX.Element => <SVGDataDownloadIcon />);
export const EditIcon = React.memo((): JSX.Element => <SVGEditIcon />);
export const ServerIcon = React.memo((): JSX.Element => <SVGServerIcon />);
export const TrashGrayIcon = React.memo((): JSX.Element => <SVGTrashGrayIcon />);
export const CloseGrayIcon = React.memo((): JSX.Element => <SVGCloseGrayIcon />);
export const IllustFileIcon = React.memo((): JSX.Element => <SVGIllustFileIcon />);
export const IllustWarningIcon = React.memo((): JSX.Element => <SVGIllustWarningIcon />);
export const FileNeutralGrayIcon = React.memo((): JSX.Element => <SVGFileNeutralGrayIcon />);
export const UserIcon = React.memo((): JSX.Element => <SVGUserIcon />);
export const DownIcon = React.memo((): JSX.Element => <SVGDownIcon />);
export const UpIcon = React.memo((): JSX.Element => <SVGUpIcon />);
export const PlusIcon = React.memo((): JSX.Element => <SVGPlusIcon />);
export const TrashWeakGrayIcon = React.memo((): JSX.Element => <SVGTrashWeakGrayIcon />);
export const EditWeakGrayIcon = React.memo((): JSX.Element => <SVGEditWeakGrayIcon />);
export const WarningIcon = React.memo((): JSX.Element => <SVGWarningIcon />);
export const ConfirmIcon = React.memo((): JSX.Element => <SVGConfirmIcon />);
export const EyeOffGray = React.memo((): JSX.Element => <SVGEyeOffGrayIcon />);
export const ListIcon = React.memo((): JSX.Element => <SVGListIcon />);
export const SettingIcon = React.memo((): JSX.Element => <SVGSettingIcon />);
export const AdminIcon = React.memo((): JSX.Element => <SVGAdminIcon />);
export const LogoutIcon = React.memo((): JSX.Element => <SVGLogoutIcon />);
export const OrganizationIcon = React.memo((): JSX.Element => <SVGOrganizationIcon />);
export const CheckedIcon = React.memo((): JSX.Element => <SVGCheckedIcon />);
export const LockIcon = React.memo((): JSX.Element => <SVGLockIcon />);
export const StrokeIcon = React.memo((): JSX.Element => <SVGStrokeIcon />);
export const EyeIcon = React.memo((): JSX.Element => <SVGEyeIcon />);
export const PinIcon = React.memo((): JSX.Element => <SVGPinIcon />);
export const UnlockIcon = React.memo((): JSX.Element => <SVGUnlockIcon />);
export const NonStrokeIcon = React.memo((): JSX.Element => <SVGNonStrokeIcon />);
export const EyeOffIcon = React.memo((): JSX.Element => <SVGEyeOffIcon />);
export const UnpinIcon = React.memo((): JSX.Element => <SVGUnpinIcon />);
export const CopyIcon = React.memo((): JSX.Element => <SVGCopyIcon />);
export const TrackIcon = React.memo((): JSX.Element => <SVGTrackIcon />);
export const TrackActiveIcon = React.memo((): JSX.Element => <SVGTrackActiveIcon />);
export const StarIcon = React.memo((): JSX.Element => <SVGStarIcon />);
export const StarFillIcon = React.memo((): JSX.Element => <SVGStarFillIcon />);
export const LocationIcon = React.memo((): JSX.Element => <SVGLocationIcon />);
export const EnterIcon = React.memo((): JSX.Element => <SVGEnterIcon />);
export const JobActionIcon = React.memo((): JSX.Element => <SVGJobActionIcon />);
export const ExitIcon = React.memo((): JSX.Element => <SVGExitIcon />);
export const ChangeIcon = React.memo((): JSX.Element => <SVGChangeIcon />);
