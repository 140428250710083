import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import Text from 'antd/lib/typography/Text';
import { Row, Col } from 'antd/lib/grid';
import { MoreOutlined } from '@ant-design/icons';
import Dropdown from 'antd/lib/dropdown';
import Progress from 'antd/lib/progress';
import moment from 'moment';

import ActionsMenuContainer from 'containers/actions-menu/actions-menu';
import Preview from 'components/common/preview';
import { ActiveInference } from 'reducers';
import { Card } from 'antd';

export interface TaskItemProps {
    taskInstance: any;
    deleted: boolean;
    hidden: boolean;
    activeInference: ActiveInference | null;
    cancelAutoAnnotation(): void;
}

class TaskItemComponent extends React.PureComponent<TaskItemProps & RouteComponentProps> {
    private renderPreview(): JSX.Element {
        const { taskInstance } = this.props;
        return (
            <Preview
                task={taskInstance}
                loadingClassName='cvat-task-item-loading-preview'
                previewWrapperClassName='cvat-task-item-preview-wrapper'
            />
        );
    }

    private renderDescription(): JSX.Element {
        // Task info
        const { taskInstance } = this.props;
        const owner = taskInstance.owner ? taskInstance.owner.username : null;

        // Get and truncate a task name
        const name = `${taskInstance.name.substring(0, 70)}${taskInstance.name.length > 70 ? '...' : ''}`;

        return (
            <Row className='cvat-task-item-description'>
                <Col span={22}>
                    <span className='cvat-item-task-name'>
                        {name}
                    </span>
                    <br />
                    {owner && (
                        <Text type='secondary'>{owner}</Text>
                    )}
                </Col>
                <Col span={2} onClick={(e) => e.stopPropagation()}>
                    <Dropdown dropdownRender={() => <ActionsMenuContainer taskInstance={taskInstance} />}>
                        <MoreOutlined className='cvat-menu-icon' />
                    </Dropdown>
                </Col>
            </Row>
        );
    }

    private renderProgress(): JSX.Element {
        const { taskInstance } = this.props;
        // Count number of jobs and performed jobs
        const numOfJobs = taskInstance.progress.totalJobs;
        const numOfCompleted = taskInstance.progress.completedJobs;
        const jobIds = taskInstance.progress.ids;

        // Progress appearance depends on number of jobs
        let progressColor = null;
        let progressText = null;
        if (numOfCompleted && numOfCompleted === numOfJobs) {
            progressColor = 'cvat-task-completed-progress';
            progressText = (
                <Text className={progressColor}>
                    Completed
                </Text>
            );
        } else if (numOfCompleted) {
            progressColor = 'cvat-task-progress-progress';
            progressText = (
                <Text className={progressColor}>
                    In Progress
                </Text>
            );
        } else {
            progressColor = 'cvat-task-pending-progress';
            progressText = (
                <Text className={progressColor}>
                    New
                </Text>
            );
        }

        const jobsProgress = numOfCompleted / numOfJobs;

        return (
            <>
                <Row justify='space-between' align='top'>
                    <Col>{progressText}</Col>
                    <Col>
                        <Text type='secondary'>{`Job ID: ${Math.min(...jobIds)} ~ ${Math.max(...jobIds)}`}</Text>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Progress
                            className='cvat-task-progress'
                            percent={jobsProgress * 100}
                            strokeColor='#FFFFFF'
                            showInfo={false}
                            strokeWidth={5}
                            size='small'
                        />
                    </Col>
                </Row>
            </>
        );
    }

    private renderFooter(): JSX.Element {
        const { taskInstance } = this.props;
        const { updatedDate } = taskInstance;

        return (
            <>
                <Text type='secondary'>Last Updated | </Text>
                <Text type='secondary'>{moment(updatedDate).fromNow()}</Text>
            </>
        );
    }

    public render(): JSX.Element {
        const {
            deleted, hidden, history, taskInstance,
        } = this.props;
        const style = {};
        if (deleted) {
            (style as any).pointerEvents = 'none';
            (style as any).opacity = 0.5;
        }

        if (hidden) {
            (style as any).display = 'none';
        }

        return (
            <Card
                bordered={false}
                cover={this.renderPreview()}
                onClick={() => history.push(`/tasks/${taskInstance.id}`)}
                style={style}
            >
                {this.renderDescription()}
                <div style={{ marginTop: 'auto' }}>
                    {this.renderProgress()}
                    {this.renderFooter()}
                </div>
            </Card>
        );
    }
}

export default withRouter(TaskItemComponent);
