import React, { useEffect, useState } from 'react';
import Text from 'antd/lib/typography/Text';
import {
    Row, Spin, Table, Tag,
} from 'antd';
import UserSelector, { User } from 'components/task-page/user-selector';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { CombinedState } from 'reducers';
// import { getJobsAsync } from 'actions/jobs-actions';
import { getStatistic } from 'actions/organization-actions';
import { SyncOutlined } from '@ant-design/icons';

interface TableItem {
    key: number | string;
    state: 'Over' | 'Regular';
    stage: 'Accepted' | 'Not accepted';
    worker: string;
    object: number;
    project: string;
    task: string;
    job: string;
    labels: string[];
}

export default function StatisticsPageComponent(): JSX.Element {
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<TableItem[]>([]);
    const [regular, setRegular] = useState<number>(0);
    const [over, setOver] = useState<number>(0);
    const [regularAccepted, setRegularAccepted] = useState<number>(0);
    const [overAccepted, setOverAccepted] = useState<number>(0);
    const dispatch = useDispatch();
    const fetching = useSelector((state: CombinedState) => state.jobs.fetching);
    const statistic = useSelector((state: CombinedState) => state.organizations.statistic);
    const [projects, setProjects] = useState<string[]>([]);
    const [tasks, setTasks] = useState<string[]>([]);
    const [labels, setLabels] = useState<string[]>([]);
    const [filteredData, setFilteredData] = useState<any>([]);

    const getJobs = (): void => {
        setLoading(true);
        dispatch(getStatistic(selectedUser?.id ?? null));
    };

    useEffect(() => {
        getJobs();
    }, [selectedUser]);

    useEffect(() => {
        setFilteredData(statistic?.jobs);
        setProjects(Array.from(new Set(statistic?.jobs.map((job: any) => job.project))));
        setTasks(Array.from(new Set(statistic?.jobs.map((job: any) => job.task))));
    }, [statistic]);

    useEffect(() => {
        const newData = filteredData?.reduce((acc: any[], job: any) => {
            acc.push({
                key: job.id,
                etc: job.etc,
                state: job.state,
                stage: job.stage,
                worker: job.worker,
                object: Object.values(job.labels)?.reduce((a: number, b: number) => a + b, 0) ?? 0,
                project: job.project,
                task: job.task,
                job: `ID: ${job.id}`,
                labels: job.labels.toString().replace(',', ', '),
            });

            return acc;
        }, []);

        setData(newData, );

        let regular = 0;
        let over = 0;
        let regularAccepted = 0;
        let overAccepted = 0;

        newData?.forEach((job: any) => {
            if (job.etc) {
                over += Number(job.object);
                if (job.stage === 'acceptance' && job.state === 'completed') {
                    overAccepted += Number(job.object);
                }
            } else {
                regular += Number(job.object);
                if (job.stage === 'acceptance' && job.state === 'completed') {
                    regularAccepted += Number(job.object);
                }
            }
        });

        setRegular(regular);
        setOver(over);
        setRegularAccepted(regularAccepted);
        setOverAccepted(overAccepted);

        let labels: string[] = [];
        filteredData?.forEach((job: any) => {
            labels = labels.concat(Object.keys(job.labels));
        });
        labels = Array.from(new Set(labels))
        labels = labels.map((label: number) => `${label}_${statistic?.labels[label]}`);
        setLabels(labels);

        setLoading(false);
    }, [filteredData]);

    const handleTableChange = (pagination, filters, sorter) => {
        let newData = [];
        if (filters['labels']) {
            newData = statistic.jobs.reduce((acc: any[], job: any) => {
                const tempLabels = {};
                filters['labels'].forEach((label: string) => {
                    const labelId = label.split('_')[0];
                    if (job.labels[labelId]) {
                        tempLabels[labelId] = job.labels[labelId];
                    }
                });
                if (Object.keys(tempLabels).length > 0) {
                    acc.push({...job, labels: tempLabels})
                }
                return acc;
            }, []);
        } else {
            newData = statistic.jobs.filter(item => {
                return Object.keys(filters).every(columnKey => {
                    const filterValues = filters[columnKey];
                    if (filterValues && filterValues.length > 0) {
                        return filterValues.includes(item[columnKey]);
                    }
                    return true;
                });
            });
        }

        setFilteredData(newData);
      };

    const columns = [
        {
            width: '90px',
            title: 'etc',
            dataIndex: 'etc',
            key: 'etc',
            filters: [
                { text: 'Regular', value: false },
                { text: 'Over', value: true },
            ],
            render: (etc: boolean) => {
                let color;
                if (etc === false) color = 'geekblue';
                else color = 'volcano';
                return (
                    <Tag color={color} style={{ width: 67 }}>
                        {etc ? 'Over' : 'Regular'}
                    </Tag>
                );
            },
        },
        {
            width: '115px',
            title: 'Stage',
            dataIndex: 'stage',
            key: 'stage',
            filters: [
                { text: 'Accepted', value: 'Accepted' },
                { text: 'Not accepted', value: 'Not accepted' },
            ],
            render: (stage: string) => {
                let color;
                if (stage === 'Accepted') color = 'geekblue';
                else color = 'volcano';
                return (
                    <Tag color={color} style={{ width: 100 }}>
                        {stage}
                    </Tag>
                );
            },
        },
        {
            title: 'Worker',
            dataIndex: 'worker',
            key: 'worker',
        },
        {
            title: 'Object',
            dataIndex: 'object',
            key: 'object',
            sorter: (a: any, b: any) => Number(a.object) - Number(b.object),
            render: (object: number) => <span>{object.toLocaleString()}</span>,
        },
        {
            title: 'Project',
            dataIndex: 'project',
            key: 'project',
            filters: projects.map((project: string) => ({ text: project, value: project })),
        },
        {
            title: 'Task',
            dataIndex: 'task',
            key: 'task',
            filters: tasks.map((task: string) => ({ text: task, value: task })),
        },
        {
            title: 'Job',
            dataIndex: 'job',
            key: 'job',
        },
        {
            title: 'Labels',
            // dataIndex: 'labels',
            key: 'labels',
            filters: labels.map((label: string) => ({ text: label, value: label })),
        }
    ];

    return (
        <Row className='cvat-jobs-page-top-bar' justify='center' align='middle'>
            <Row justify='center'>
                <div
                    style={{
                        width: 1250,
                        display: 'flex',
                        justifyContent: 'space-between',
                        verticalAlign: 'middle',
                    }}
                >
                    <Text className='cvat-statistics-header'>
                        Statistics
                        <SyncOutlined
                            spin={loading || fetching}
                            disabled={loading || fetching}
                            style={{ marginLeft: 15 }}
                            onClick={getJobs}
                        />
                    </Text>
                    <span style={{ marginTop: 20 }}>
                        <UserSelector
                            className='cvat-job-assignee-selector'
                            value={selectedUser}
                            onSelect={(value: User | null): void => {
                                if (value !== null) setSelectedUser({ id: value?.id, username: value?.username });
                                else setSelectedUser(null);
                            }}
                            suffix
                        />
                    </span>
                </div>
            </Row>

            <Row justify='start' style={{ width: 1250 }}>
                <Spin spinning={loading || fetching}>
                    <Row justify='space-between' style={{ width: selectedUser ? 430 : 350 }}>
                        <Text className='cvat-statistics-header sub'>
                            All Objects :
                            {(regular + over).toLocaleString()}
                            {' / '}
                            {(regularAccepted + overAccepted).toLocaleString()}
                        </Text>
                    </Row>
                </Spin>
            </Row>

            <Row justify='start' style={{ width: 1250 }}>
                <Spin spinning={loading || fetching}>
                    <Row justify='space-between' style={{ width: selectedUser ? 430 : 350 }}>
                        <Text className='cvat-statistics-header sub2'>
                            Regular Objects :
                            {regular.toLocaleString()}
                            {' / '}
                            {regularAccepted.toLocaleString()}
                        </Text>
                    </Row>
                </Spin>
            </Row>

            <Row justify='start' style={{ width: 1250 }}>
                <Spin spinning={loading || fetching}>
                    <Row justify='space-between' style={{ width: selectedUser ? 430 : 350 }}>
                        <Text className='cvat-statistics-header sub3'>
                            Over Objects :
                            {over.toLocaleString()}
                            {' / '}
                            {overAccepted.toLocaleString()}
                        </Text>
                    </Row>
                </Spin>
            </Row>

            <Row justify='center'>
                <Spin size='large' spinning={loading || fetching}>
                    <Table
                        style={{ width: 1250, marginTop: 20 }}
                        rowClassName='cvat-statistics-table-row'
                        dataSource={data}
                        pagination={false}
                        scroll={{ y: 530 }}
                        columns={columns}
                        onChange={handleTableChange}
                    />
                </Spin>
            </Row>
        </Row>
    );
}
