import React from 'react';
import { Col, Row } from 'antd/lib/grid';
import Select from 'antd/lib/select';
import Checkbox from 'antd/lib/checkbox';
import Text from 'antd/lib/typography/Text';
import Icon from '@ant-design/icons';
import CVATTooltip from 'components/common/cvat-tooltip';
import { ChangeIcon } from 'icons';

export interface LabelMapperItemValue {
    labelId: number;
    newLabelName: string | null;
}

export interface LabelMapperItemProps {
    label: any;
    projectLabels?: any[];
    value: LabelMapperItemValue;
    labelMappers: LabelMapperItemValue[];
    onChange: (value: LabelMapperItemValue) => void;
}

export default function LabelMapperItem(props: LabelMapperItemProps): JSX.Element {
    const {
        label, value, onChange, projectLabels, labelMappers,
    } = props;

    const labelNames = labelMappers.map((mapper) => mapper.newLabelName).filter((el) => el);

    return (
        <Row className='cvat-move-task-label-mapper-item' align='middle'>
            <Col span={6}>
                {label.name.length > 10 ? (
                    <CVATTooltip overlay={label.name}>
                        <span className='cvat-move-task-label-mapper-item-tag'>
                            <svg height='8' width='8' style={{ fill: label.color }}>
                                <circle cx='4' cy='4' r='4' strokeWidth='0' />
                            </svg>
                            <Text>{`${label.name.slice(0, 10)}...`}</Text>
                        </span>
                    </CVATTooltip>
                ) : (
                    <span className='cvat-move-task-label-mapper-item-tag'>
                        <svg height='8' width='8' style={{ fill: label.color }}>
                            <circle cx='4' cy='4' r='4' strokeWidth='0' />
                        </svg>
                        <Text>{label.name}</Text>
                    </span>
                )}
            </Col>
            <Col span={1} offset={1}>
                <Icon component={ChangeIcon} />
            </Col>
            <Col span={7} offset={1}>
                <Select
                    className='cvat-move-task-label-mapper-item-select'
                    disabled={typeof projectLabels === 'undefined'}
                    value={value.newLabelName || ''}
                    onChange={(_value) => onChange({
                        ...value,
                        newLabelName: _value as string,
                    })}
                >
                    {projectLabels
                        ?.filter((_label) => !labelNames.includes(_label.name))
                        .map((_label) => (
                            <Select.Option key={_label.id} value={_label.name}>
                                {_label.name}
                            </Select.Option>
                        ))}
                </Select>
            </Col>
            <Col span={7} offset={1}>
                <Checkbox
                    disabled
                    checked={true}
                    className='cvat-move-task-label-mapper-item-checkbox'
                >
                    Clear attributes
                </Checkbox>
            </Col>
        </Row>
    );
}
