import './styles.scss';
import React from 'react';
import Layout from 'antd/lib/layout';

import ControlsSideBarContainer from 'containers/annotation-page/review-workspace/controls-side-bar/controls-side-bar';
import CanvasLayout from 'components/annotation-page/canvas/grid-layout/canvas-layout';
import ObjectSideBarComponent from 'components/annotation-page/standard-workspace/objects-side-bar/objects-side-bar';
import ObjectsListContainer from 'containers/annotation-page/standard-workspace/objects-side-bar/objects-list';
import CanvasContextMenuContainer from 'containers/annotation-page/canvas/canvas-context-menu';
import IssueAggregatorComponent from 'components/annotation-page/review/issues-aggregator';
import ObjectsLeftSideBarComponent from 'components/annotation-page/standard-workspace/objects-side-bar/objects-left-side-bar';

export default function ReviewWorkspaceComponent(): JSX.Element {
    return (
        <>
            <Layout.Header className='cvat-review-header'>
                <ControlsSideBarContainer />
            </Layout.Header>
            <Layout.Content className='cvat-annotation-layout-content'>
                <Layout hasSider className='cvat-review-workspace'>
                    <ObjectsLeftSideBarComponent objectsList={<ObjectsListContainer readonly />} />
                    <CanvasLayout />
                    <ObjectSideBarComponent objectsList={<ObjectsListContainer readonly />} />
                    <CanvasContextMenuContainer readonly />
                    <IssueAggregatorComponent />
                </Layout>
            </Layout.Content>
        </>
    );
}
